<template>
  <component-frame title="Layout">
    <div class="sg-page__heading"></div>

    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <img src="assets/img/ls-layout-1.png" alt="" />
        </div>
        <div class="sg-component__code">
          <div
            class="sg-component__toggle js-toggle-code"
            data-text-swap="Hide Code"
            v-toggleCode
          >
            Show Code
          </div>
          <pre v-highlight><code class="language-html">

                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Header should always include logo and angled light grey utility bar.
            If the logo is not descriptive enough the name of the site/product
            can be included next to the logo in the grey area
          </li>
          <li>
            If there is no primary navigation the blue bar should be hidden
          </li>
          <li>Main content and sidebar should not exceed 1200px width.</li>
          <li>
            Either primary or secondary footer should contain main navigation
            links
          </li>
          <li>
            Footer utility bar is generally used for privacy information, help,
            copyright, etc.
          </li>
        </ul>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "layout",
  data() {
    return {
      title: "Layout"
    };
  }
};
</script>
